import { ERRORS_FOR_PIRCE } from '../../../constants/tripValidator';

const requiredDecimal = (value) => value !== '' && typeof value === 'number';

const isNegative = (value) => value < 0;

const validateVat = (vat) => {
  if (!requiredDecimal(vat.rate) || !requiredDecimal(vat.amount)) {
    return ERRORS_FOR_PIRCE.INVALID_VAT.EMPTY_FIELDS;
  }

  if (isNegative(vat.rate) || isNegative(vat.amount)) {
    return ERRORS_FOR_PIRCE.INVALID_VAT.NEGATIVE_FIELDS;
  }

  return '';
};

const validationObj = {
  base: {
    msg: ERRORS_FOR_PIRCE.NO_BASE,
    fn: requiredDecimal,
  },
  fee: {
    msg: ERRORS_FOR_PIRCE.NO_FEE,
    fn: requiredDecimal,
  },
  commission: {
    msg: ERRORS_FOR_PIRCE.NO_COMMISSION,
    fn: requiredDecimal,
  },
  tax: {
    msg: ERRORS_FOR_PIRCE.NO_TAX,
    fn: requiredDecimal,
  },
  taxes: {
    msg: ERRORS_FOR_PIRCE.NO_TAXES,
    fn: requiredDecimal,
  },
  vats: {
    msg: '',
    fn: (vats) => vats.map(validateVat),
  },
  providerCommission: {
    msg: ERRORS_FOR_PIRCE.NO_PROVIDER_COMMISSION,
    fn: requiredDecimal,
  },
  fare: {
    msg: ERRORS_FOR_PIRCE.NO_FARE,
    fn: requiredDecimal,
  },
  tariffPrice: {
    msg: ERRORS_FOR_PIRCE.NO_TARIFF_PRICE,
    fn: requiredDecimal,
  },
};

class PriceValidator {
  isValid(field, value) {
    const validation = validationObj[field];
    if (!validation) return '';

    if (field === 'vats') {
      const err = validation.fn(value);

      return err.filter(Boolean).length === 0 ? '' : err[0];
    }

    return validation.fn(value) ? '' : validation.msg;
  }
}

export default PriceValidator;
