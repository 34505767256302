import moment from 'moment';

import lodashReplaces from '../../../utils/lodashReplaces';
import { pickValue } from '../../../utils/trip';

import { ERRORSFORALL, ERRORSFORTRAIN } from '../../../constants/tripValidator';
import { FIELDS } from '../../../constants/trips';
import { TRAIN_PROVDERS, TRAIN_TRANSIT_DOCUMENT_TYPE } from '../../../constants/train';

const employees = (id) => Boolean(id);

const requireValue = (value) => value !== '' && Number(value) !== 0;

const requireValueCar = (value) => value !== '' && (Number(value) !== 0 || value === '00');

const requireNumber = (value) => value !== '';

const requireTransitDocument = (value) => value === '' || value !== TRAIN_TRANSIT_DOCUMENT_TYPE.NO_VALUE;

const requiredDate = (value) => {
  if (typeof value === 'string') {
    return moment(value).isValid();
  }

  return moment.isMoment(value) && value.isValid();
};

const validTrainEdit = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },

  ProjectId: {
    fn: pickValue,
    msg: [ERRORSFORALL.NOPROJECT, ERRORSFORALL.NO_PROJECTS],
  },

  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },

  Employees: {
    fn: employees,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },

  OrderId: {
    fn: requireValue,
    msg: ERRORSFORTRAIN.NOORDER,
  },

  SaleOrderId: {
    fn: requireValue,
    msg: ERRORSFORTRAIN.NO_SALE_ORDER,
  },

  TicketId: {
    fn: requireValue,
    msg: ERRORSFORTRAIN.NOTICKET,
  },

  ProviderName: {
    fn: requireValue,
    msg: ERRORSFORTRAIN.NO_CARRIER,
  },

  ProviderComission: {
    fn: requireNumber,
    msg: ERRORSFORTRAIN.NO_FEE,
  },

  Route: {
    DateDepartureLocal: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_DEPARTURE_DATE,
    },

    TimeDepartureLocal: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_DEPARTURE_TIME,
    },

    DateArriveLocal: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_ARRIVE_DATE,
    },

    TimeArriveLocal: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_ARRIVE_TIME,
    },

    DateDeparture: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_DEPARTURE_DATE,
    },

    TimeDeparture: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_DEPARTURE_TIME,
    },

    DateArrive: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_ARRIVE_DATE,
    },

    TimeArrive: {
      fn: requiredDate,
      msg: ERRORSFORTRAIN.NO_ARRIVE_TIME,
    },

    StationArrive: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_ARRIVE_STATION,
    },

    StationDepart: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_DEPARTURE_STATION,
    },

    TrainNumber: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_TRAIN_NUMBER,
    },

    CarNumber: {
      fn: requireValueCar,
      msg: ERRORSFORTRAIN.NO_CAR_NUMBER,
    },

    CarTypeDescription: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_CAR_TYPE_DESCRIPTION,
    },

    ClassService: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_CLASS_SERVICE,
    },
  },

  TransitDocumentType: {
    fn: requireTransitDocument,
    msg: ERRORSFORTRAIN.NO_TRANSIT_DOCUMENT,
  },

  CarrierDetails: {
    CarrierFull: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_CARRIER,
    },
  },

  TrainPricing: {
    TariffPrice: {
      fn: requireValue,
      msg: ERRORSFORTRAIN.NO_TARIFF_PRICE,
    },
    ServiceTva: {
      fn: requireNumber,
      msg: ERRORSFORTRAIN.NO_AMOUNT,
    },
  },

  PriceDetails: {
    Commission: {
      fn: requireNumber,
      msg: ERRORSFORTRAIN.NO_СOMMISION,
    },
  },

  guid: {
    fn: requireValue,
    msg: ERRORSFORTRAIN.NO_PICK_FILE,
  },

  Description: {
    fn: requireValue,
    msg: ERRORSFORALL.NODESCRIPTION,
  },

};
const validTrainPartEdit = {
  ...validTrainEdit,
  Description: {
    fn: () => true,
    msg: '',
  },
};

class TrainValidator {
  isValid(path, value, isPartEdit = false) {
    const validationRules = isPartEdit ? validTrainPartEdit : validTrainEdit;
    const validationPartObj = lodashReplaces.getValueInObjByPath(validationRules, path);

    if (!validationPartObj) {
      return '';
    }

    const { fn, msg } = validationPartObj;

    return fn(value) ? '' : msg;
  }

  getValidationFields = (obj, validStorage, mandatoryProject, projects) => {
    const res = Object.keys(obj).reduce((acc, key) => {
      const field = obj[key];

      if (field instanceof Object && !(field instanceof Array) && !moment.isMoment(field)) {
        return { ...acc, [key]: this.getValidationFields(field, validStorage[key], mandatoryProject, projects) };
      }

      const partValidObj = validStorage[key];
      if (!partValidObj) {
        return acc;
      }
      const { fn, msg } = partValidObj;

      if (key === FIELDS.PROJECTID) {
        let value;
        const isValidValue = fn(field) ? '' : msg[0];

        if (mandatoryProject) {
          value = projects.length ? isValidValue : msg[1];

          return { ...acc, [key]: value };
        }

        value = fn(field) ? '' : msg[0];

        return { ...acc, [key]: value };
      }

      if (key === FIELDS.SALE_ORDER_ID && obj.ProviderName === TRAIN_PROVDERS.TeleTrain) {
        return { ...acc, [key]: '' };
      }

      if (key === FIELDS.ORDER_ID && obj.ProviderName === TRAIN_PROVDERS.RZHDCart) {
        return { ...acc, [key]: '' };
      }

      const value = fn(field) ? '' : msg;

      return { ...acc, [key]: value };
    }, {});

    return res;
  };

  isValidFields = (fields) => Object.keys(fields)
    .every((key) => {
      const field = fields[key];

      return field instanceof Object && !moment.isMoment(field) ? this.isValidFields(field) : !field;
    });

  getValidation = (fields, mandatoryProject, projects, isPartEdit = false) => {
    const validationRules = isPartEdit ? validTrainPartEdit : validTrainEdit;
    const validation = this.getValidationFields(fields, validationRules, mandatoryProject, projects);

    return { validation, isValid: this.isValidFields(validation) };
  };
}

export default TrainValidator;
